import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/kyoUAGigdto">
    <SEO title="The Long Arm of the Lord - Hebrews" />
  </Layout>
)

export default SermonPost
